<template>
  <form method="post">
    <button
      type="button"
      class="btn btn-primary bg-gradient-primary btn-block"
      @click="dialog = true"
    >
      <span v-if="!genieeOpportunityIdValue"> Geniee商談作成 </span>
      <span v-else> Geniee商談更新 </span>
    </button>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      style="z-index: 1050"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <v-text-field
                    v-model="opportunityNameValue"
                    label="商談名"
                    name="name"
                  />
                </div>
                <div class="form-group">
                  <label
                    for="sales_stage_id"
                  >営業ステージ</label>
                  <select
                    v-model="salesStageId"
                    name="sales_stage_id"
                    class="form-control"
                    required
                  >
                    <option
                      value="0"
                      selected
                    >
                      選択してください
                    </option>
                    <option
                      v-for="r in salesStageIdList"
                      :key="r.id"
                      :value="r.id"
                    >
                      {{ r.name }}
                    </option>
                  </select>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            閉じる
          </v-btn>
          <v-btn
            v-if="!genieeOpportunityIdValue"
            type="button"
            color="blue darken-1"
            text
            @click="store"
          >
            実行
          </v-btn>
          <v-btn
            v-else
            type="button"
            color="blue darken-1"
            text
            @click="update"
          >
            実行
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-progress-circular
      v-if="connecting"
      :indeterminate="progress === 0"
      :value="progress"
      color="light-blue"
    />
  </form>
</template>

<script>
import Axios from "axios";

export default {
    props: {
        apiToken: {
            type: String,
            required: true,
        },
        estimateId: {
            type: Number,
            required: true,
        },
        opportunityName: {
            type: String,
            required: true,
        },
        genieeOpportunityId: {
            type: String,
            default: null,
        },
        salesStageIdList: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            connecting: false,
            dialog: false,
            opportunityNameValue: "",
            genieeOpportunityIdValue: null,
            progress: 100,
            salesStageId: 0,
        };
    },
    created: function () {
        this.genieeOpportunityIdValue = this.genieeOpportunityId;
        this.opportunityNameValue = this.opportunityName;

        if (this.genieeOpportunityIdValue) {
            Axios.get(
                `/api/v1/estimate/store/geniee/opportunity/` +
                    this.genieeOpportunityIdValue,
                {
                    params: {
                        estimate_id: this.estimateId,
                    },
                    headers: {
                        Authorization: "Bearer " + this.apiToken,
                        Accept: "application/json",
                    },
                }
            )
                .then((res) => {
                    this.connecting = false;
                    this.progress = 100;
                    this.opportunityNameValue = res.data.name;
                    this.salesStageId = res.data.sales_stage_id;
                })
                .catch((error) => {
                    this.connecting = false;
                    this.progress = 100;
                    console.log(error);
                });
        }
    },
    methods: {
        /**
         *
         */
        store() {
            if (this.salesStageId == 0) {
                alert("営業ステージを選択してください");
                return;
            }
            this.dialog = false;
            this.connecting = true;
            this.progress = 0;
            Axios.post(
                `/api/v1/estimate/store/geniee/opportunity`,
                {
                    estimate_id: this.estimateId,
                    name: this.opportunityNameValue,
                    sales_stage_id: parseInt(this.salesStageId),
                },
                {
                    headers: {
                        Authorization: "Bearer " + this.apiToken,
                        Accept: "application/json",
                    },
                }
            )
                .then((res) => {
                    this.connecting = false;
                    this.progress = 100;
                    this.genieeOpportunityIdValue = res.data;
                    this.$emit(
                        "geniee-opportunity-id",
                        this.genieeOpportunityIdValue
                    );
                })
                .catch((error) => {
                    this.connecting = false;
                    this.progress = 100;
                    console.log(error);
                    if (error.response.data.message) {
                        switch (error.response.data.message) {
                            // 仮(後で調整)
                            case "HTTP request returned status code 401":
                                alert(
                                    "ユーザー名・パスワードが正しくありません"
                                );
                                break;
                            default:
                                alert(error.response.data.message);
                        }
                        return;
                    }
                    switch (error.response.status) {
                        case 401:
                            alert("認証エラー");
                            break;
                        default:
                            alert(error);
                    }
                });
        },
        /**
         *
         */
        update() {
            if (this.salesStageId == 0) {
                alert("営業ステージを選択してください");
                return;
            }
            this.dialog = false;
            this.connecting = true;
            this.progress = 0;
            Axios.patch(
                `/api/v1/estimate/store/geniee/opportunity/` +
                    this.genieeOpportunityIdValue,
                {
                    estimate_id: this.estimateId,
                    name: this.opportunityNameValue,
                    sales_stage_id: parseInt(this.salesStageId),
                },
                {
                    headers: {
                        Authorization: "Bearer " + this.apiToken,
                        Accept: "application/json",
                    },
                }
            )
                .then(() => {
                    this.connecting = false;
                    this.progress = 100;
                })
                .catch((error) => {
                    this.connecting = false;
                    this.progress = 100;
                    console.log(error);
                    if (error.response.data.message) {
                        switch (error.response.data.message) {
                            // 仮(後で調整)
                            case "HTTP request returned status code 401":
                                alert(
                                    "ユーザー名・パスワードが正しくありません"
                                );
                                break;
                            default:
                                alert(error.response.data.message);
                        }
                        return;
                    }
                    switch (error.response.status) {
                        case 401:
                            alert("認証エラー");
                            break;
                        default:
                            alert(error);
                    }
                });
        },
        /**
         *
         * @param {Number} num
         */
        updateGenieeOpportunityId(num) {
            this.genieeOpportunityIdValue = num;
        },
    },
};
</script>
