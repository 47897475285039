<template>
  <div>
    <div class="form-row my-4">
      <div class=" col-xs-12 col-sm-6 col-md-6 py-0">
        <label>ステータス</label>
        <v-select
          v-model="localStatusSelected"
          :options="statusListForSelect"
          :reduce="(name) => name.id"
          label="name"
        ></v-select>
        <input type="hidden" name="status" :value="localStatusSelected">
      </div>
    </div>
    <div class="form-row my-4">
      <!-- 出荷指示情報セレクトボックス -->
      <ref-shipping-instruction
        ref="refShippingInstruction"
        :shipping-label="shippingLabel"
        :shipping-headers="shippingHeaders"
        :shipping-items="shippingItems"
        :selected="selected"
        @shipmentdate-change="shipmentdateChange"
      ></ref-shipping-instruction>
    </div>
    <div class="form-row my-4">
      <ref-customerset-only-customer
        ref="RefCustomersetOnlyCustomer"
        :kentem-id-url="kentemIdUrl"
        :customer-url="customerUrl"
        :dealer-url="dealerUrl"
        :kentem-id-default="kentemIdDefault" >
      </ref-customerset-only-customer>
    </div>
    <div class="form-row my-4" v-if="this.displayOrderDate">
      <div class=" col-xs-6 col-sm-3 col-md-3 py-0">
        <datepicker-field
          :value="orderDate"
          :token="apiToken"
          name="order_date"
          label="発注日"
          kentem-holidays
          clearable
          outlined
          dense
        ></datepicker-field>
      </div>
    </div>
    <div class="form-row my-4">
      <div class=" col-xs-6 col-sm-3 col-md-3 py-0">
        <datepicker-field
          :value="receiveDate"
          :token="apiToken"
          name="receive_date"
          label="納品日"
          kentem-holidays
          clearable
          outlined
          dense
        ></datepicker-field>
      </div>
      <div class=" col-xs-6 col-sm-3 col-md-3 py-0">
        <v-text-field
          v-model="localShipmentDate"
          name="shipment_date"
          label="出荷日"
          readonly
          outlined
          dense
        ></v-text-field>
        <input type="hidden" name="shipment_date" :value="localShipmentDate">
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import refShippingInstruction from "./ref-shipping-instruction.vue";
import RefCustomersetOnlyCustomer from "./ref-customerset-only-customer.vue";
export default {
  components: {
    refShippingInstruction,
    RefCustomersetOnlyCustomer,
  },
  props: {
    shippingLabel: {
      type: String,
      default: "出荷指示情報",
    },
    shippingItems: {
      type: Array,
      default: () => [],
    },
    shippingHeaders: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Number,
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    customerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_customer/data_table",
    },
    dealerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_sales/data_table",
    },
    kentemIdDefault: {
      type: String,
      default: "",
    },
    orderDate: {
      type: String,
      default: "",
    },
    shipmentDate: {
      type: String,
      default: "",
    },
    receiveDate: {
      type: String,
      default: "",
    },
    apiToken: {
      type: String,
      default: "",
    },
    displayOrderDate: {
      type: Boolean,
      default: true,
    },
    arrayShippingInstructionIdToStatusAndEstimateId: {
      type: Object,
      default: [],
    },
    isLinkShippingInstruction: {
      type: Boolean,
      default: true,
    },
    statusListForSelect: {
      type: Array,
      required: true,
    },
    statusSelected: {
      type: [String, Number],
      required: false,
      default: "",
    },
  },
  watch: {
    statusSelected(newVal) {
      this.localStatusSelected = newVal;
    },
    localStatusSelected() {
      this.changeStatus();
    }
  },
  data() {
    return {
      localShipmentDate: this.shipmentDate,
      localStatusSelected: this.statusSelected,
      newCustomer: [],
      newShipmentDate: ''
    };
  },
  methods: {
    shipmentdateChange(shipment_id) {
      const estimate_id = this.arrayShippingInstructionIdToStatusAndEstimateId[shipment_id].estimate_id;
      const newStatus = this.arrayShippingInstructionIdToStatusAndEstimateId[shipment_id].status;
      if (!this.isLinkShippingInstruction) {
        this.getShipmentDateByEstimateId(estimate_id);
        this.getCustomerByEstimateId(estimate_id);
        this.localStatusSelected = newStatus;
        this.localStatusSelected = newStatus;
      }
    },
    getCustomerByEstimateId(estimate_id) {
      axios.get("/api/v1/stocks/getcustomer/"+ estimate_id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.apiToken
        }
      })
      .then(response => {
        this.newCustomer = response.data;
        const item = {};
        item['kentem_id'] = this.newCustomer.customer_kentem_id;
        item['name'] = this.newCustomer.name;
        this.$refs.RefCustomersetOnlyCustomer.selectCustomerItem(item);
      })
      .catch(error => {
        alert(error.response.data.message);
      });
    },
    getShipmentDateByEstimateId(estimate_id) {
      axios.get("/api/v1/stocks/shipmentdate/"+ estimate_id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.apiToken
        }
      })
      .then(response => {
        this.newShipmentDate = response.data;
        this.localShipmentDate = this.newShipmentDate;
      })
      .catch(error => {
        alert(error.response.data.message);
      });
    },
    changeStatus() {
      if(this.localStatusSelected===2 && this.selected!==''){
        if(confirm('出荷済みから在庫に戻すと紐づけが外れます。本当に在庫にしますか？')){
          this.$refs.refShippingInstruction.clearShippingInfo();
          this.$refs.RefCustomersetOnlyCustomer.clearSelection();
          this.localShipmentDate = '';
        }
      }
    }
  },
};
</script>