<template>
  <div>
    <div class="status-container">
      <div
        class="status-box"
        v-for="status in statuses"
        :key="status"
        @click="searchByStatus(status)"
        style="position: relative"
      >
        <div class="status-info">
          <p class="status-label">{{ getStatusLabel(status) }}</p>
          <div
            class="count"
            :style="{
              color: 'white',
              'background-color': '#ff8080',
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              padding: '4px 8px',
              'border-radius': '50%',
            }"
          >
            {{ statusCounts[status] !== undefined ? statusCounts[status] : 0 }}
          </div>
        </div>
      </div>
    </div>

    <v-card-title>
      <p class="my-2">検索結果：{{ this.searchResults.length }}件</p>
    </v-card-title>

    <form @submit.prevent="search" class="py-3">
      <div class="row">
        <!-- 見積IDの入力フィールド -->
        <div class="col-md-2">
          <label for="estimateId">見積ID</label>
          <input
            type="number"
            name="estimateId"
            v-model="searchCriteria.estimate_id"
            class="form-control"
            placeholder="見積IDを入力してください"
          />
        </div>
      </div>

      <div class="search-toggle-container">
        <button
          type="button"
          class="search-toggle-btn"
          @click="toggleSearchFields"
        >
          <i
            :class="
              searchFieldsVisible ? 'fas fa-toggle-on' : 'fas fa-toggle-off'
            "
          ></i>
          <span>{{
            searchFieldsVisible ? "検索項目を非表示" : "検索項目を表示"
          }}</span>
        </button>
      </div>

      <!-- エンドユーザー情報 -->
      <div v-if="searchFieldsVisible" class="row">
        <!-- エンドユーザー名の入力フィールド -->
        <div class="col-md-3">
          <label for="customerName">エンドユーザー名</label>
          <input
            type="text"
            name="customer_name"
            v-model="searchCriteria.customer_name"
            class="form-control"
          />
        </div>

        <!-- エンドユーザー名(かな)の入力フィールド -->
        <div class="col-md-3">
          <label for="customerNameKana">エンドユーザー名(かな)</label>
          <input
            type="text"
            name="customer_name_kana"
            v-model="searchCriteria.customer_name_kana"
            class="form-control"
          />
        </div>

        <!-- エンドユーザーKentemIDの入力フィールド -->
        <div class="col-md-3">
          <label for="customerKentemId">エンドユーザーKentemID</label>
          <input
            type="text"
            name="customer_kentem_id"
            v-model="searchCriteria.customer_kentem_id"
            class="form-control"
          />
        </div>

        <!-- エンドユーザー整理番号の入力フィールド -->
        <div class="col-md-3">
          <label for="referenceNumber">エンドユーザー整理番号</label>
          <input
            type="text"
            v-model="searchCriteria.reference_number"
            class="form-control"
          />
        </div>
      </div>

      <!-- 販売店情報 -->
      <div v-if="searchFieldsVisible" class="row">
        <!-- 販売店名の入力フィールド -->
        <div class="col-md-3">
          <label for="salesName">販売店名</label>
          <input
            type="text"
            name="sales_name"
            v-model="searchCriteria.sales_name"
            class="form-control"
          />
        </div>

        <!-- 販売店名(かな)の入力フィールド -->
        <div class="col-md-3">
          <label for="salesNameKana">販売店名(かな)</label>
          <input
            type="text"
            name="sales_name_kana"
            v-model="searchCriteria.sales_name_kana"
            class="form-control"
          />
        </div>

        <!-- 販売店KentemIDの入力フィールド -->
        <div class="col-md-3">
          <label for="salesKentemId">販売店KentemID</label>
          <input
            type="text"
            name="sales_kentem_id"
            v-model="searchCriteria.sales_kentem_id"
            class="form-control"
          />
        </div>
      </div>

      <!-- 日付情報 -->
      <div v-if="searchFieldsVisible" class="row">
        <!-- 受注日の入力フィールド -->
        <div class="col-md-3">
          <label for="orderDate">受注日</label>
          <input
            type="date"
            name="order_date"
            v-model="searchCriteria.order_date"
            class="form-control"
          />
        </div>

        <!-- 出荷日の入力フィールド -->
        <div class="col-md-3">
          <label for="shipmentDate">出荷日</label>
          <input
            type="date"
            name="shipment_date"
            v-model="searchCriteria.shipment_date"
            class="form-control"
          />
        </div>

        <!-- 売上日の入力フィールド -->
        <div class="col-md-3">
          <label for="salesDate">売上日</label>
          <input
            type="date"
            name="sales_date"
            v-model="searchCriteria.sales_date"
            class="form-control"
          />
        </div>
      </div>

      <!-- その他情報 -->
      <div v-if="searchFieldsVisible" class="row">
        <!-- 出荷形態の入力フィールド -->
        <div class="col-md-3">
          <label for="shipment_way">出荷形態</label>
          <v-select
            v-model="selectedShipmentWayValue"
            :options="shipmentWayStatuses"
            multiple
            label="label"
          />
        </div>

        <!-- 受注確定レポートの入力フィールド -->
        <div class="col-md-3">
          <label for="departmentCode">受注確定レポート</label>
          <v-select
            v-model="selectedOrderConfirmationReportOutputStatusValue"
            :options="orderConfirmationReportOutputStatuses"
          />
        </div>

        <!-- 納品書の入力フィールド -->
        <div class="col-md-3">
          <label for="orderConfirmationCreatedAt">納品書</label>
          <v-select
            v-model="selectedDeliverySlipOutputStatusValue"
            :options="deliverySlipOutputStatuses"
          />
        </div>
      </div>

      <div v-if="searchFieldsVisible" class="row">
        <!-- 出荷指示のステータスの入力フィールド -->
        <div class="col-md-3">
          <label for="status">ステータス</label>
          <v-select
            v-model="selectedShipmentStatusValue"
            :options="shipmentStatuses"
          />
        </div>
        <!-- 注文内容確認者の入力フィールド -->
        <div class="col-md-3">
          <label for="shipping_instruction_creater">注文内容確認者</label>
          <v-select
            v-model="selectedConfirmationCompleterValue"
            :options="confirmationCompleterList"
          />
        </div>

        <!-- 注文内容確認日の入力フィールド -->
        <!--
        <div class="col-md-3">
          <label for="order_confirmation_created_at">注文内容確認日</label>
          <input
            type="date"
            name="order_confirmation_created_at"
            v-model="searchCriteria.order_confirmation_created_at"
            class="form-control"
          />
        </div>
      --></div>

      <!-- 検索ボタンとリセットボタン -->
      <div class="row">
        <div class="col-md-6 d-flex justify-content-start">
          <button class="search-toggle-btn mr-2">
            <i class="fas fa-search"></i>検索
          </button>
          <button type="button" class="reset-toggle-btn" @click="resetForm">
            リセット
          </button>
        </div>
      </div>
    </form>

    <!-- 検索結果を表示する場所 -->
    <div v-if="isLoading" class="loading-container">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <div v-else>
      <v-card class="mt-2" v-if="paginatedResults.length > 0">
        <div class="row">
          <button
            type="button"
            class="complete-toggle-btn"
            @click="completeSelectedItems"
          >
            出荷完了
          </button>

          <div style="margin-right: 10px"></div>

          <button
            type="button"
            class="pending-toggle-btn"
            @click="pendingSelectedItems"
          >
            出荷保留
          </button>

          <div style="margin-right: 10px"></div>

          <button
            type="button"
            class="pending-toggle-btn"
            @click="releasePendingSelectedItems"
          >
            保留解除
          </button>

          <div style="margin-right: 10px"></div>

          <button
            type="button"
            class="cancel-toggle-btn"
            @click="cancelSelectedItems"
          >
            出荷キャンセル
          </button>
        </div>
        <v-card-text>
          <table class="table">
            <tr>
              <th>
                <input
                  type="checkbox"
                  v-model="selectAll"
                  @change="selectAllItems"
                />
              </th>
              <th>見積ID</th>
              <th>取引先</th>
              <th>販売店</th>
              <th>レポート</th>
              <th>整理番号</th>
              <th>ステータス</th>
              <th>出荷形態</th>
              <th>出荷日</th>
              <th>納品書</th>
              <th></th>
              <th></th>
            </tr>
            <tr
              v-for="data in paginatedResults"
              :key="data.shipping_instruction_id"
            >
              <td>
                <input
                  type="checkbox"
                  v-model="selectedInstructions"
                  :value="data.shipping_instruction_id"
                />
              </td>
              <td>
                <a :href="'/admin/estimate/conf/' + data.estimate_id">{{
                  data.estimate_id
                }}</a>
              </td>
              <td>
                <a :href="'/kentem_information/' + data.customer_kentem_id">
                  {{ customerNames[data.estimate_id] }}</a
                >
              </td>
              <td>
                {{ salesNames[data.estimate_id] }}
              </td>
              <td>
                <div
                  class="hanko-circle-red active"
                  v-if="data.order_confirmation_report_outputted"
                >
                  &nbsp;済&nbsp;
                </div>
              </td>
              <td>{{ data.reference_number }}</td>
              <td>{{ getStatusLabel(data.status) }}</td>
              <td>{{ getShipmentWayLabel(data.shipment_way) }}</td>
              <td>{{ data.shipment_date }}</td>
              <td>
                <div
                  class="hanko-circle-red active"
                  v-if="data.delivery_slip_outputted"
                >
                  &nbsp;済&nbsp;
                </div>
              </td>
              <td>
                <a
                  :href="
                    '/shipping_instruction/detail/edit/' +
                    data.shipping_instruction_id
                  "
                  ><i class="fas fa-tools"
                /></a>
              </td>
              <td>
                <form
                  :action="
                    '/shipping_instruction/delete/single/' +
                    data.shipping_instruction_id
                  "
                  method="post"
                >
                  <input type="hidden" name="_method" value="DELETE" />
                  <input type="hidden" name="_token" :value="csrf" />
                  <v-btn
                    type="button"
                    icon
                    class="bg-gradient-danger shadow"
                    @click="deleteItem(data.shipping_instruction_id)"
                  >
                    <i class="far fa-trash-alt" />
                  </v-btn>
                </form>
              </td>
            </tr>
          </table>
        </v-card-text>
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          :total-visible="7"
          circle
        ></v-pagination>
      </v-card>
      <v-card v-else class="mt-2">
        <v-card-text>
          {{ $t("No results found.") }}
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import "vuetify/dist/vuetify.min.css";
import Vue from "vue";
import Vuetify from "vuetify";
Vue.use(Vuetify);
export default {
  props: {
    token: {
      type: String,
      required: true,
    },
    csrf: {
      type: String,
      required: true,
    },
    customerNames: {
      type: Object,
      required: true,
    },
    salesNames: {
      type: Object,
      required: true,
    },
    shippingInstructions: {
      type: Array,
      required: true,
    },
    shipmentWayStatuses: {
      type: Array,
      required: true,
    },
    shipmentStatuses: {
      type: Array,
      required: true,
    },
    orderConfirmationReportOutputStatuses: {
      type: Array,
      required: true,
    },
    deliverySlipOutputStatuses: {
      type: Array,
      required: true,
    },
    confirmationCompleterList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statuses: [5, 0, 1, 2, 3, 4, 99], // ステータス一覧
      searchTerm: "", // 検索語句
      searchCriteria: {
        estimate_id: "",
        customer_name: "",
        customer_name_kana: "",
        customer_kentem_id: "",
        customer_shokon_code: "",
        sales_name: "",
        sales_name_kana: "",
        sales_kentem_id: "",
        sales_shokon_code: "",
        order_date: "",
        shipment_date: "",
        sales_date: "",
        order_confirmation_created_at: "",
        department_code: null,
        shipmentConfirmationDateTime: "",
        shipment_way: null,
        is_output_order_confirmation_report: null,
        is_output_delivery_slip: null,
        shipping_instruction_creater: "",
        status: "",
      },
      isLoading: true, // ローディング状態を管理するデータ
      searchResults: [], // 検索結果
      showSearchFields: false, // 検索項目の表示/非表示を制御するデータプロパティ
      searchFieldsVisible: false,
      statusCounts: {},
      selectAll: false,
      selectedInstructions: [],
      selectedShipmentWayValue: null,
      selectedShipmentStatusValue: { label: "", value: "" },
      selectedOrderConfirmationReportOutputStatusValue: null,
      selectedDeliverySlipOutputStatusValue: null,
      selectedConfirmationCompleterValue: null,
      currentPage: 1,
      itemsPerPage: 20,
    };
  },
  mounted() {
    this.loadSearchCriteriaFromSession();
    this.loadSearchResultsFromSession(); // セッションから検索結果を読み込む
    this.fetchStatusCounts();
  },
  computed: {
    paginatedResults() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.searchResults.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.searchResults.length / this.itemsPerPage);
    },
  },
  watch: {
    selectedInstructions: function (newSelectedInstructions, _) {
      this.$emit("selected-instructions-updated", newSelectedInstructions);
    },
    selectedShipmentWayValue(newValues) {
      if (newValues && newValues.length > 0) {
        this.searchCriteria.shipment_way = newValues
          .map((option) => option.value)
          .join(",");
      }
    },
    selectedOrderConfirmationReportOutputStatusValue(newValue) {
      if (newValue && newValue.value !== undefined) {
        this.searchCriteria.is_output_order_confirmation_report =
          newValue.value;
      } else {
        this.searchCriteria.is_output_order_confirmation_report = "";
      }
    },
    selectedDeliverySlipOutputStatusValue(newValue) {
      if (newValue && newValue.value !== undefined) {
        this.searchCriteria.is_output_delivery_slip = newValue.value;
      } else {
        this.searchCriteria.is_output_delivery_slip = "";
      }
    },
    selectedConfirmationCompleterValue(newValue) {
      if (newValue && newValue.value !== undefined) {
        this.searchCriteria.shipping_instruction_creater = newValue.value;
      } else {
        this.searchCriteria.shipping_instruction_creater = "";
      }
    },
    selectedShipmentStatusValue(newValue) {
      if (newValue && newValue.value !== undefined) {
        this.searchCriteria.status = newValue.value;
      } else {
        this.searchCriteria.status = "";
      }
    },
  },
  created() {},
  methods: {
    /**
     * 初期検索をするメソッド
     */
    fetchInitialResults() {
      this.searchResults = this.shippingInstructions.slice(0, 20);
      this.isLoading = false;
    },
    /**
     * 検索するメソッド
     */
    async search() {
      this.isLoading = true;
      try {
        const res = await Axios.get("/api/v1/shipping_instruction/search", {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          params: this.searchCriteria,
        });
        if (res.data) {
          this.searchResults = res.data.shipping_instructions;
          this.customerNames = res.data.customer_names;
          this.salesNames = res.data.sales_names;
          this.currentPage = 1;
          this.selectAll = false;
          this.selectedInstructions = [];
          this.saveSearchCriteriaToSession();
          this.saveSearchResultsToSession();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * 検索条件をリセットするメソッド
     */
    resetForm() {
      this.searchCriteria = {
        estimate_id: "",
        customer_name: "",
        customer_name_kana: "",
        customer_kentem_id: "",
        customer_shokon_code: "",
        sales_name: "",
        sales_name_kana: "",
        sales_kentem_id: "",
        sales_shokon_code: "",
        order_date: "",
        shipment_date: "",
        sales_date: "",
        order_confirmation_created_at: "",
        department_code: null,
        shipmentConfirmationDateTime: "",
        shipment_way: "",
        selectedShipmentWayValue: null,
        is_output_order_confirmation_report: null,
        is_output_delivery_slip: null,
        shipping_instruction_creater: "",
        status: "",
      };
      this.selectedShipmentWayValue = null;
      this.selectedOrderConfirmationReportOutputStatusValue = null;
      this.selectedDeliverySlipOutputStatusValue = null;
      this.selectedShipmentStatusValue = null;
      this.selectedConfirmationCompleterValue = "";
      this.selectedInstructions = [];
      this.selectAll = false;
      this.currentPage = 1;
      // リセット後にセッションからも削除
      sessionStorage.removeItem("searchCriteria");
    },
    /**
     * 検索条件をセッションに保管するメソッド
     */
    saveSearchCriteriaToSession() {
      //検索項目
      sessionStorage.setItem(
        "searchCriteria",
        JSON.stringify(this.searchCriteria)
      );

      //出荷形態
      sessionStorage.setItem(
        "selectedShipmentWayValue",
        JSON.stringify(this.selectedShipmentWayValue)
      );

      //受注確定レポート
      sessionStorage.setItem(
        "selectedOrderConfirmationReportOutputStatusValue",
        JSON.stringify(this.selectedOrderConfirmationReportOutputStatusValue)
      );

      //納品書
      sessionStorage.setItem(
        "selectedDeliverySlipOutputStatusValue",
        JSON.stringify(this.selectedDeliverySlipOutputStatusValue)
      );

      //ステータス
      sessionStorage.setItem(
        "selectedShipmentStatusValue",
        JSON.stringify(this.selectedShipmentStatusValue)
      );

      //注文内容確認者
      sessionStorage.setItem(
        "selectedConfirmationCompleterValue",
        JSON.stringify(this.selectedConfirmationCompleterValue)
      );
    },
    saveSearchResultsToSession() {
      sessionStorage.setItem(
        "searchResults",
        JSON.stringify(this.searchResults)
      );
    },
    /**
     * セッションから検索条件を読み込むメソッド
     */
    loadSearchCriteriaFromSession() {
      //検索項目
      const savedSearchCriteria = sessionStorage.getItem("searchCriteria");
      if (savedSearchCriteria) {
        this.searchCriteria = JSON.parse(savedSearchCriteria);
      }

      //出荷形態
      const savedSelectedShipmentWayValue = JSON.parse(
        sessionStorage.getItem("selectedShipmentWayValue")
      );
      if (savedSelectedShipmentWayValue) {
        this.selectedShipmentWayValue = savedSelectedShipmentWayValue;
      }

      //受注確定レポート
      const savedSelectedOrderConfirmationReportOutputStatusValue = JSON.parse(
        sessionStorage.getItem(
          "selectedOrderConfirmationReportOutputStatusValue"
        )
      );
      if (savedSelectedOrderConfirmationReportOutputStatusValue) {
        this.selectedOrderConfirmationReportOutputStatusValue =
          savedSelectedOrderConfirmationReportOutputStatusValue;
      }

      //納品書
      const savedSelectedDeliverySlipOutputStatusValue = JSON.parse(
        sessionStorage.getItem("selectedDeliverySlipOutputStatusValue")
      );
      if (savedSelectedDeliverySlipOutputStatusValue) {
        this.selectedDeliverySlipOutputStatusValue =
          savedSelectedDeliverySlipOutputStatusValue;
      }

      //ステータス
      const savedSelectedShipmentStatusValue = JSON.parse(
        sessionStorage.getItem("selectedShipmentStatusValue")
      );
      if (savedSelectedShipmentStatusValue) {
        this.selectedShipmentStatusValue = savedSelectedShipmentStatusValue;
      }

      //注文内容確認者
      const savedSelectedConfirmationCompleterValue = JSON.parse(
        sessionStorage.getItem("selectedConfirmationCompleterValue")
      );
      if (savedSelectedConfirmationCompleterValue) {
        this.selectedConfirmationCompleterValue =
          savedSelectedConfirmationCompleterValue;
      }
    },
    /**
     * セッションから検索結果を読み込むメソッド
     */
    loadSearchResultsFromSession() {
      const savedSearchResults = sessionStorage.getItem("searchResults");
      if (savedSearchResults) {
        this.searchResults = JSON.parse(savedSearchResults);
        this.isLoading = false;
      } else {
        // 初期状態の検索結果を表示する
        this.fetchInitialResults();
      }
    },
    /**
     * 検索項目の表示/非表示を切り替えるメソッド
     */
    toggleSearchFields() {
      this.searchFieldsVisible = !this.searchFieldsVisible;
    },
    /**
     * ステータスごとに検索するメソッド
     * @param {Number} status
     */
    searchByStatus(status) {
      this.resetForm();
      this.selectedShipmentStatusValue = {
        label: this.getStatusLabel(status),
        value: status,
      };
      Axios.get(`/api/v1/shipping_instruction/search/${status}`, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => {
          if (res.data) {
            this.searchResults = res.data.shipping_instructions;
            this.saveSearchCriteriaToSession();
            this.saveSearchResultsToSession();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /**
     * ステータスごとの明細件数を取得するメソッド
     * @param {Number} status
     */
    getStatusCount(status) {
      return new Promise((resolve, reject) => {
        Axios.get(`/api/v1/shipping_instruction/status/count/${status}`, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
          .then((res) => {
            if (res.data) {
              resolve(res.data); // 成功時にデータを返す
            } else {
              reject(new Error("No data received")); // データがない場合はエラーを返す
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error); // エラーが発生した場合はエラーを返す
          });
      });
    },
    /**
     * ステータスのラベルを取得するメソッド
     * @param {Number} status
     */
    getStatusLabel(status) {
      switch (status) {
        case 0:
          return "出荷指示作成中";
        case 1:
          return "出荷指示完了";
        case 2:
          return "①チェック完了";
        case 3:
          return "②チェック完了";
        case 4:
          return "出荷";
        case 5:
          return "保留";
        case 99:
          return "出荷キャンセル";
        default:
          return "";
      }
    },
    /**
     * 出荷形態ののラベルを取得するメソッド
     * @param {Number} shipmentWay
     */
    getShipmentWayLabel(shipmentWay) {
      switch (shipmentWay) {
        case 1:
          return "奈良委託_ヤマト";
        case 2:
          return "富士本社_ヤマト";
        case 3:
          return "富士本社_日通";
        case 4:
          return "転送処理";
        case 5:
          return "請求のみ";
        case 6:
          return "KJK";
        case 7:
          return "奈良委託_ヤマト_MQR";
        default:
          return "";
      }
    },
    /**
     * 全選択するメソッド
     */
    selectAllItems() {
      if (this.selectAll) {
        this.selectedInstructions = this.searchResults.map(
          (data) => data.shipping_instruction_id
        );
      } else {
        this.selectedInstructions = [];
      }
    },
    /**
     * 指定したレコードを削除するメソッド
     * @param {Number} id
     */
    deleteItem(id) {
      if (!confirm("本当に削除しますか?")) {
        return;
      }
      this.deleteItemWithoutConfirm(id);
    },
    /**
     * 指定したレコードを削除するメソッド
     * @param {Number} id
     */
    deleteItemWithoutConfirm(id) {
      Axios.delete(`/api/v1/shipping_instruction/delete/single/` + id, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => {
          if (res.data) {
            alert("削除できました");
            location.reload();
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     * 選択レコードを出荷完了にするメソッド
     */
    completeSelectedItems() {
      if (this.selectedInstructions.length === 0) {
        alert("出荷処理完了とするデータを選択してください。");
        return;
      }

      const hasInvalidStatus = this.selectedInstructions.every((itemId) => {
        const item = this.searchResults.find(
          (data) => data.shipping_instruction_id === itemId
        );
        return (
          item.shipment_way === 4 ||
          item.shipment_way === 5 ||
          item.shipment_way === 6 ||
          item.status === 3
        );
      });

      if (!hasInvalidStatus) {
        alert("選択したデータに出荷処理完了できないデータ含まれています。");
        return;
      }

      //更新処理
      const selectedIds = this.selectedInstructions;
      Axios.patch(
        `/api/v1/shipping_instruction/status/update/list`,
        {
          id_list: selectedIds,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            alert("更新が完了しました。");
            this.shippingInstructions = res.data;
            location.reload();
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     * 選択レコードを出荷キャンセルにするメソッド
     */
    cancelSelectedItems() {
      if (this.selectedInstructions.length === 0) {
        alert("出荷キャンセルとするデータを選択してください。");
        return;
      }

      // ポップアップで再度実行するかどうかユーザーに確認
      const confirmPopup = confirm(
        "見積IDで検索して出荷キャンセルしていますか？\n本当に出荷キャンセルしてよいですか？"
      );

      if (!confirmPopup) {
        return;
      }

      //更新処理
      const selectedIds = this.selectedInstructions;
      Axios.patch(
        `/api/v1/shipping_instruction/cancel/list`,
        {
          id_list: selectedIds,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            alert("出荷キャンセル処理が完了しました");
            this.shippingInstructions = res.data;
            location.reload();
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     * 選択レコードを出荷保留にするメソッド
     */
     pendingSelectedItems() {
      if (this.selectedInstructions.length === 0) {
        alert("出荷保留とするデータを選択してください。");
        return;
      }

      // 0:出荷指示作成中・1:出荷指示完了のみ保留にできる
      const hasInvalidStatus = this.selectedInstructions.every((itemId) => {
        const item = this.searchResults.find(
          (data) => data.shipping_instruction_id === itemId
        );
        return (item.status === 0 || item.status === 1);
      });
      if (!hasInvalidStatus) {
        alert("出荷指示作成中・出荷指示完了のデータのみ保留にできます。");
        return;
      }

      //更新処理
      const selectedIds = this.selectedInstructions;
      Axios.patch(
        `/api/v1/shipping_instruction/pending/list`,
        {
          id_list: selectedIds,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            alert("出荷保留処理が完了しました");
            this.shippingInstructions = res.data;
            location.reload();
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     * 選択レコードを保留解除にするメソッド
     */
     releasePendingSelectedItems() {
      if (this.selectedInstructions.length === 0) {
        alert("保留解除とするデータを選択してください。");
        return;
      }

      // 5:出荷保留のみ保留解除できる
      const hasInvalidStatus = this.selectedInstructions.every((itemId) => {
        const item = this.searchResults.find(
          (data) => data.shipping_instruction_id === itemId
        );
        return (item.status === 5);
      });
      if (!hasInvalidStatus) {
        alert("出荷保留のデータのみを選択してください。");
        return;
      }

      //更新処理
      const selectedIds = this.selectedInstructions;
      Axios.patch(
        `/api/v1/shipping_instruction/release_pending/list`,
        {
          id_list: selectedIds,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            alert("出荷保留処理が完了しました");
            this.shippingInstructions = res.data;
            location.reload();
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
          console.log(error);
        });
    },
    /**
     * ステータスごとの明細数を取得するメソッド
     */
    fetchStatusCounts() {
      // Promise.allを使って非同期の処理をまとめる
      Promise.all(
        this.statuses.map((status) => {
          return this.getStatusCount(status)
            .then((count) => {
              // データ取得後にstatusCountsにセット
              this.$set(this.statusCounts, status, count);
            })
            .catch((error) => {
              console.error(error);
            });
        })
      );
    },
    /**
     * 選択した出荷形態で検索するメソッド
     * @param {Number} selectedValue
     */
    updateShipmentWay(selectedValue) {
      this.searchCriteria.shipment_way = selectedValue;
    },
  },
};
</script>

<style scoped>
/* Font Awesome のスタイルシートを読み込む */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css");

.status-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
}

.status-box {
  background-color: #fff; /* 白色の背景 */
  color: #000; /* 黒色のテキスト */
  border-radius: 8px;
  padding: 10px;
  margin: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.status-box:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* 影の調整 */
}

.status-info {
  text-align: center;
}

.status-label {
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: bold;
}

.count-wrapper {
  position: relative;
  text-align: right;
}
.count {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  border-radius: 50%;
}

.search-toggle-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-bottom: 20px;
}

.search-toggle-btn {
  display: flex;
  align-items: center;
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-toggle-btn:hover {
  background-color: #2980b9;
}

.search-toggle-btn i {
  margin-right: 8px;
}

.reset-toggle-btn {
  display: flex;
  align-items: center;
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-toggle-btn:hover {
  background-color: #2980b9;
}

.reset-toggle-btn i {
  margin-right: 8px;
}

.search-btn {
  display: flex;
  align-items: center;
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-btn:hover {
  background-color: #2980b9;
}

.search-btn i {
  margin-right: 8px;
}

.complete-toggle-btn {
  display: flex;
  align-items: center;
  background-color: lightcoral;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.complete-toggle-btn:hover {
  background-color: lightcoral;
}

.complete-toggle-btn i {
  margin-right: 8px;
}

.cancel-toggle-btn {
  display: flex;
  align-items: center;
  background-color: lightgrey;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-toggle-btn:hover {
  background-color: lightgrey;
}

.pending-toggle-btn {
  display: flex;
  align-items: center;
  background-color: #ffc04d;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-toggle-btn i {
  margin-right: 8px;
}
</style>
