<template>
  <div class="col-xs-12 col-sm-2 col-md-2 py-0">
    <div class="mb-2">
      <geniee-opportunity-upsert
        ref="refUpsert"
        :api-token="apiToken"
        :estimate-id="estimateId"
        :opportunity-name="opportunityName"
        :geniee-opportunity-id="genieeOpportunityIdValue"
        :sales-stage-id-list="salesStageIdList"
        @geniee-opportunity-id="onupdateGenieeOpportunityId"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
  apiToken: {
    type: String,
    required: true,
  },
  estimateId: {
    type: Number,
    required: true,
  },
  opportunityName: {
    type: String,
    required: true,
  },
  genieeOpportunityId: {
    type: String,
    default: null,
  },
  salesStageIdList: {
    type: Array,
    required: true,
  },
  },
  data() {
    return {
      genieeOpportunityIdValue: null,
    };
  },
  created: function () {
    this.genieeOpportunityIdValue = this.genieeOpportunityId;
  },
  methods: {
    /**
     *
     * @param {Number} num
     */
    onupdateGenieeOpportunityId(num) {
      this.genieeOpportunityIdValue = num;
      this.$refs.refUpsert.updateGenieeOpportunityId(num);
      this.$refs.refDelete.updateGenieeOpportunityId(num);
    },
  },
};
</script>
