<template>
  <p>{{ opportunityNumber }}</p>
</template>
<script>
import Axios from "axios";
export default {
  props: {
    estimateId: {
      type: Number,
      default: "",
    },
    apiToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      opportunityNumber: "",
    }
  },
  created: function () {
    if(this.estimateId){
      this.getOpportunityNumberById(this.estimateId);
    }
  },
  methods: {
    /**
     *
     * @param {String} id
     */
    getOpportunityNumberById(id) {
      // IDの先頭文字に応じてモードとエンドポイントを設定
      Axios.get(`/api/v1/geniee/opportunity/estimate_id/${id}`, {
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
        },
      })
        .then((res) => {
          if (res.data) {
            this.opportunityNumber = res.data.opportunity_number ?? "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>