<template>
  <div class="col-xs-12 col-sm-12 col-md-12 py-0">
    <div class="form-row">
      <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <input
          type="checkbox"
          name="is_np_classfication"
          id="is_seminar"
          value="0"
          v-model="checkedNpvalues"
        />
        <label for="is_seminar">ＮＰ掛け払い（有料セミナー）</label>
        <input
          type="checkbox"
          name="is_np_classfication"
          id="is_cloud"
          value="1"
          v-model="checkedNpvalues"
        />
        <label for="is_cloud">ＮＰ掛け払い（クラウド）</label>
        <div class="form-group">
          <label class="col-form-label">
            <span>{{ dealerLabelData }}</span>
            <span v-if="dealerRequired" class="badge badge-primary">{{
              $t("required")
            }}</span>
            <span v-else class="badge badge-secondary">{{
              $t("optional")
            }}</span>
          </label>
          <input
            type="text"
            :value="dealerFormValue"
            :name="dealerName"
            :disabled="this.dealerDisabled"
            :readonly="this.dealerReadonly"
            :required="dealerRequired"
            class="form-control"
          />
          <input
            type="hidden"
            :name="salesShokonCode"
            :value="dealerShokonCodeFormValue"
            :required="dealerRequired"
            readonly="readonly"
          />
          <v-dialog
            v-model="dealerDialog"
            persistent
            scrollable
            max-width="1000px"
            style="z-index: 1050"
          >
            <v-card>
              <v-toolbar color="light-blue darken-4" dark>
                <v-toolbar-title>{{ dealerTitleData }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon>
                  <v-icon @click="dealerDialog = false">
                    fas fa-window-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <ref-customerset-customercard
                        :callback="selectDealerItem"
                        :customer-headers="dealerHeaders"
                        :customer-items="dealerItems"
                        :customer-loading="dealerLoading"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="dealerDialog = false">
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <div class="form-row">
            <label class="col-form-label">
              <span>{{ dealerZipDefaultLabel }}</span>
              <span v-if="dealerRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="dealerZipFormValue"
              :name="dealerZip"
              :disabled="this.dealerZipDisabled"
              :readonly="this.dealerZipReadonly"
              :required="dealerZipRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ dealerAdd1DefaultLabel }}</span>
              <span v-if="dealerRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="dealerAdd1FormValue"
              :name="dealerAdd1"
              :disabled="this.dealerAdd1Disabled"
              :readonly="this.dealerAdd1Readonly"
              :required="dealerAdd1Required"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ dealerAdd2DefaultLabel }}</span>
              <span v-if="dealerAdd2Required" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="dealerAdd2FormValue"
              :name="dealerAdd2"
              :disabled="this.dealerAdd2Disabled"
              :readonly="this.dealerAdd2Readonly"
              class="form-control"
            />
          </div>
          <div class="form-row">
            <label class="col-form-label">
              <span>{{ dealertNameDefaultLabel }}</span>
              <span v-if="dealertNameRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="dealertNameFormValue"
              :name="dealertName"
              :disabled="this.dealertNameDisabled"
              :readonly="this.dealertNameReadonly"
              class="form-control"
            />
          </div>
          <label class="col-form-label">
            <span>{{ reeseCompanyNameDefaultLabel }}</span>
            <span v-if="reeseCompanyNameRequired" class="badge badge-primary">{{
              $t("required")
            }}</span>
            <span v-else class="badge badge-secondary">{{
              $t("optional")
            }}</span>
          </label>
          <input
            type="text"
            :value="reeseCompanyNameFormValue"
            :disabled="false"
            :readonly="false"
            :required="reeseCompanyNameRequired"
            :name="reeseCompanyName"
            class="form-control"
          />
          <input
            type="hidden"
            :name="reeseCompanyName"
            :value="reeseCompanyNameFormValue"
            readonly="readonly"
          />
          <input
            type="hidden"
            :name="reeseCompanyShokonCode"
            :value="reeseCompanyShokonCodeFormValue"
            readonly="readonly"
          />
          <v-dialog
            v-model="reeseCompanyNameDialog"
            persistent
            scrollable
            max-width="1000px"
            style="z-index: 1050"
          >
            <template #activator="{ on }">
              <v-btn color="primary" dark v-on="on">
                {{ $t("reference") }}
              </v-btn>
            </template>
            <v-card>
              <v-toolbar color="light-blue darken-4" dark>
                <v-toolbar-title>{{
                  reeseCompanyNameTitleData
                }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon>
                  <v-icon @click="reeseCompanyNameDialog = false">
                    fas fa-window-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-card
                        class="mx-auto"
                        elevation="2"
                        outlined
                        color="light-blue lighten-5"
                      >
                        <v-data-table
                          :headers="reeseCompanyHeaders"
                          :items="reeseCompanyItems"
                          :items-per-page="10"
                          class="elevation-1"
                          :loading="reeseLoading"
                          :loading-text="loadingLabel"
                          multi-sort
                        >
                          <template #item="{ item }">
                            <tr @click="selectreeseItem(item)">
                              <td
                                v-for="header in reeseCompanyHeaders"
                                :key="header.value"
                              >
                                {{ item[header.value] }}
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="reeseCompanyNameDialog = false"
                >
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  props: {
    dealerDefaultLabelName: {
      type: String,
      default: "sales_name",
    },
    dealerDefaultValue: {
      type: String,
      default: "dealer_name",
    },
    dealerZipDefaultValue: {
      type: String,
      default: "",
    },
    dealerAdd1DefaultValue: {
      type: String,
      default: "",
    },
    dealerAdd2DefaultValue: {
      type: String,
      default: "",
    },
    dealerIdName: {
      type: String,
      default: "kentem_id",
    },
    dealerLabel: {
      type: String,
      default: "",
    },
    dealerZipLabel: {
      type: String,
      default: "",
    },
    dealerLabelKey: {
      type: String,
      default: "sales_name",
    },
    dealerName: {
      type: String,
      default: "billing_company",
    },
    dealerZip: {
      type: String,
      default: "billing_zip",
    },
    dealerAdd1: {
      type: String,
      default: "billing_address1",
    },
    dealerAdd2: {
      type: String,
      default: "billing_address2",
    },
    dealertName: {
      type: String,
      default: "billing_contact_name",
    },
    salesShokonCode: {
      type: String,
      default: "billing_shokon_code",
    },
    reeseCompanyName: {
      type: String,
      default: "reese_company_name",
    },
    reeseCompanyShokonCode: {
      type: String,
      default: "reese_company_shokon_code",
    },
    dealerRequired: {
      type: Boolean,
      default: true,
    },
    dealerZipRequired: {
      type: Boolean,
      default: true,
    },
    dealerAdd1Required: {
      type: Boolean,
      default: true,
    },
    dealerAdd2Required: {
      type: Boolean,
      default: false,
    },
    dealerTitle: {
      type: String,
      default: "",
    },
    dealerDefaultLabelName: {
      type: String,
      default: "sales_name",
    },
    dealerDefaultValue: {
      type: String,
      default: "",
    },
    dealerIdName: {
      type: String,
      default: "kentem_id",
    },
    dealerLabel: {
      type: String,
      default: "",
    },
    dealerLabelKey: {
      type: String,
      default: "sales_name",
    },
    dealerRequired: {
      type: Boolean,
      default: true,
    },
    dealertNameRequired: {
      type: Boolean,
      default: false,
    },
    dealerTitle: {
      type: String,
      default: "",
    },
    dealerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_sales/data_table",
    },
    reeseUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/reese/data_table",
    },
    estimateOrder: {
      type: Object,
      required: true,
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    kentemId: {
      type: String,
      default: null,
    },
    customerKentemId: {
      type: String,
      default: null,
    },
    referenceDefaultLabelName: {
      type: String,
      default: "reference_number",
    },
    referenceDefaultValue: {
      type: [String, Number],
      default: "",
    },
    referenceIdName: {
      type: String,
      default: "reference_number",
    },
    referenceLabel: {
      type: String,
      default: "",
    },
    referenceLabelKey: {
      type: String,
      default: "reference_number",
    },
    referenceName: {
      type: String,
      default: "reference_number",
    },
    referenceRequired: {
      type: Boolean,
      default: true,
    },
    referenceTitle: {
      type: String,
      default: "",
    },
    sendtoRequired: {
      type: Boolean,
      default: true,
    },
    salessegmentRequired: {
      type: Boolean,
      default: true,
    },
    sendtoDefault: {
      type: String,
      default: document.getElementById("sendto"),
    },
    salessegmentDefault: {
      type: String,
      default: document.getElementById("salessegment"),
    },
    contactName: {
      type: String,
      default: "contact_name",
    },
    apiToken: {
      type: String,
      required: true,
    },
    reeseCompanyNameRequired: {
      type: Boolean,
      default: false,
    },
    salesSegment: {
      type: Number,
      default: null,
    },
    isConfirmBack: {
      type: Number,
      default: 0,
    },
    oldBillingCompany: {
      type: String,
      default: "",
    },
    oldBillingShokonCode: {
      type: String,
      default: "",
    },
    oldBillingZip: {
      type: String,
      default: "",
    },
    oldBillingAddressLine: {
      type: String,
      default: "",
    },
    oldBillingAddressLineSecond: {
      type: String,
      default: "",
    },
    oldBillingContactName: {
      type: String,
      default: "",
    },
    oldReeseCompanyName: {
      type: String,
      default: "",
    },
    oldReeseCompanyShokonCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bearer: "",
      dealerZipDefaultLabel: this.$i18n.translate("郵便番号"),
      dealertNameDefaultLabel: this.$i18n.translate("担当者名"),
      dealerAdd1DefaultLabel: this.$i18n.translate("住所1"),
      dealerAdd2DefaultLabel: this.$i18n.translate("住所2"),
      reeseCompanyNameDefaultLabel: this.$i18n.translate("リース会社名"),
      dealerDialog: false,
      dealerDetail: false,
      reeseCompanyNameDialog: false,
      dealerFormValue: this.dealerDefaultValue,
      dealerZipFormValue: this.dealerZipDefaultValue,
      dealertNameFormValue: "",
      dealerAdd1FormValue: this.dealerAdd1DefaultValue,
      dealerAdd2FormValue: this.dealerAdd2DefaultValue,
      dealerShokonCodeFormValue: "",
      reeseCompanyNameFormValue: "",
      reeseCompanyShokonCodeFormValue: "",
      reeseCompanyHeaders: [],
      reeseCompanyItems: [],
      dealerHeaders: [],
      dealerItems: [],
      dealerLabelData: "",
      dealerZipLabelData: "",
      dealerLabelName: this.dealerDefaultLabelName,
      dealerLabelValue: "",
      dealerTitleData: "",
      reeseCompanyNameTitleData: "",
      reeseCompanyNameLabelValue: "",
      reeseCompanyCmsIdLabelName: "cms_id",
      reeseCompanyLabelName: "cms_mei1",
      dealerDefaultLabel: this.$i18n.translate("会社名"),
      dealerDefaultTitle: this.$i18n.translate("販売店情報一覧"),
      dealerLoading: true,
      reeseLoading: true,
      dealerSearch: "",
      billingItems: [],
      loadingLabel: this.$i18n.translate("loading"),
      searchLabel: this.$i18n.translate("search"),
      selected: "",
      sendtoValue: this.sendtoDefault,
      selessegmentValue: this.salessegmentDefault,
      tokenUrl: "/api/v1/token",
      dealerShokonCode: "",
      dealerDisabled: false,
      dealerReadonly: true,
      dealerZipDisabled: false,
      dealerZipReadonly: true,
      dealerAdd1Disabled: false,
      dealerAdd1Readonly: true,
      dealerAdd2Disabled: false,
      dealerAdd2Readonly: true,
      dealertNameDisabled: false,
      dealertNameReadonly: true,
      checkedNpvalues: [],
    };
  },
  created: function () {
    this.dealerLabelValue =
      this.estimateOrder.billing_company !== null &&
      this.estimateOrder.billing_company !== undefined
        ? this.estimateOrder.billing_company
        : "";
    this.dealerFormValue =
      this.estimateOrder.billing_company !== null &&
      this.estimateOrder.billing_company !== undefined
        ? this.estimateOrder.billing_company
        : "";
    this.dealerShokonCodeFormValue =
      this.estimateOrder.billing_shokon_code !== null &&
      this.estimateOrder.billing_shokon_code !== undefined
        ? this.estimateOrder.billing_shokon_code
        : "";
    this.dealerZipFormValue =
      this.estimateOrder.billing_zip !== null &&
      this.estimateOrder.billing_zip !== undefined
        ? this.estimateOrder.billing_zip
        : "";
    this.dealerAdd1FormValue =
      this.estimateOrder.billing_address1 !== null &&
      this.estimateOrder.billing_address1 !== undefined
        ? this.estimateOrder.billing_address1
        : "";
    this.dealerAdd2FormValue =
      this.estimateOrder.billing_address2 !== null &&
      this.estimateOrder.billing_address2 !== undefined
        ? this.estimateOrder.billing_address2
        : "";
    this.dealertNameFormValue =
      this.estimateOrder.billing_contact_name !== null &&
      this.estimateOrder.billing_contact_name !== undefined
        ? this.estimateOrder.billing_contact_name
        : "";
    this.reeseCompanyNameFormValue =
      this.estimateOrder.reese_company_name !== null &&
      this.estimateOrder.reese_company_name !== undefined
        ? this.estimateOrder.reese_company_name
        : "";
    this.reeseCompanyShokonCodeFormValue =
      this.estimateOrder.reese_company_name !== null &&
      this.estimateOrder.reese_company_name !== undefined
        ? this.estimateOrder.reese_company_name
        : "";

    if (this.isConfirmBack) {
      this.dealerLabelValue =
        this.oldBillingCompany !== null && this.oldBillingCompany !== undefined
          ? this.oldBillingCompany
          : "";
      this.dealerFormValue =
        this.oldBillingCompany !== null && this.oldBillingCompany !== undefined
          ? this.oldBillingCompany
          : "";
      this.dealerShokonCodeFormValue =
        this.oldBillingShokonCode !== null &&
        this.oldBillingShokonCode !== undefined
          ? this.oldBillingShokonCode
          : "";
      this.dealerZipFormValue =
        this.oldBillingZip !== null && this.oldBillingZip !== undefined
          ? this.oldBillingZip
          : "";
      this.dealerAdd1FormValue =
        this.oldBillingAddressLine !== null &&
        this.oldBillingAddressLine !== undefined
          ? this.oldBillingAddressLine
          : "";
      this.dealerAdd2FormValue =
        this.oldBillingAddressLineSecond !== null &&
        this.oldBillingAddressLineSecond !== undefined
          ? this.oldBillingAddressLineSecond
          : "";
      this.dealertNameFormValue =
        this.oldBillingContactName !== null &&
        this.oldBillingContactName !== undefined
          ? this.oldBillingContactName
          : "";
      this.reeseCompanyNameFormValue =
        this.oldReeseCompanyName !== null &&
        this.oldReeseCompanyName !== undefined
          ? this.oldReeseCompanyName
          : "";
      this.reeseCompanyShokonCodeFormValue =
        this.oldReeseCompanyShokonCode !== null &&
        this.oldReeseCompanyShokonCode !== undefined
          ? this.oldReeseCompanyShokonCode
          : "";
    }

    this.dealerLabelName = this.dealerDefaultLabelName ?? this.dealerIdName;
    this.dealerLabelName = this.dealerDefaultLabelName ?? this.dealerIdName;
    this.referenceLabelName =
      this.referenceDefaultLabelName ?? this.referenceIdName;
    if (this.dealerLabel === "") {
      this.dealerLabelData = this.dealerDefaultLabel;
    }
    if (this.dealerZipLabel === "") {
      this.dealerZipLabelData = this.dealerZipDefaultLabel;
    }
    if (this.dealerTitle === "") {
      this.dealerTitleData = this.dealerDefaultTitle;
    }
    if (this.dealerLabel === "") {
      this.dealerLabelData = this.dealerDefaultLabel;
    }
    if (this.dealerTitle === "") {
      this.dealerTitleData = this.dealerDefaultTitle;
    }
    if (this.dealerShokonCodeFormValue == "") {
      this.getTokenAndLoadList();
    }
  },
  watch: {
    dealerZipDisabled: function (newdealerZipDisabled, _) {
      this.dealerZipDisabled = newdealerZipDisabled;
    },
    dealerZipReadonly: function (newdealerZipReadonly, _) {
      this.dealerZipReadonly = newdealerZipReadonly;
    },
    dealerAdd1Disabled: function (newdealerAdd1Disabled, _) {
      this.dealerAdd1Disabled = newdealerAdd1Disabled;
    },
    dealerAdd1Readonly: function (newdealerAdd1Readonly, _) {
      this.dealerAdd1Readonly = newdealerAdd1Readonly;
    },
    dealerAdd2Disabled: function (newdealerAdd2Disabled, _) {
      this.dealerAdd2Disabled = newdealerAdd2Disabled;
    },
    dealerAdd2Readonly: function (newdealerAdd2Readonly, _) {
      this.dealerAdd2Readonly = newdealerAdd2Readonly;
    },
    dealertNameDisabled: function (newdealertNameDisabled, _) {
      this.dealertNameDisabled = newdealertNameDisabled;
    },
    dealertNameReadonly: function (newdealertNameReadonly, _) {
      this.dealertNameReadonly = newdealertNameReadonly;
    },
    checkedNpvalues: function (newcheckedNpvalues, _) {
      if (
        newcheckedNpvalues.includes("0") ||
        newcheckedNpvalues.includes("1")
      ) {
        this.noCanEnterDealerInfo();
        this.clearDealerInfo();
      } else {
        this.canEnterDealerInfo();
      }
    },
  },
  methods: {
    /**
     *
     */
    async loadList() {
      this.dealerLoading = true;
      this.reeseLoading = true;
      if (this.dealerFormValue !== "" && this.dealerLabelKey !== "") {
        this.dealerLabelValue = this.$i18n.translate("loading");
      }

      const data = await this.getData();
      if (data.dealer) {
        this.dealerHeaders = data.dealer.headers;
        this.dealerItems = data.dealer.items;
      }

      if (data.reese) {
        this.reeseCompanyHeaders = data.reese.headers;
        this.reeseCompanyItems = data.reese.items;
      }

      if (this.dealerShokonCodeFormValue !== "") {
        const defaultItem = this.dealerItems.find(
          (row) => row["shokon_code"] === this.dealerShokonCodeFormValue
        );
        this.dealerLabelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.dealerLabelKey]
            : this.$i18n.translate("unknown");
      }

      //リースの場合
      if (this.salesSegment == 5) {
        this.reeseCompanyNameRequired = true;
      }

      if (this.isConfirmBack == 0) {
        if (
          this.kentemId !== "" &&
          this.dealerDefaultValue !== "" &&
          !Object.keys(this.estimateOrder).length
        ) {
          this.dealerFormValue = this.dealerDefaultValue;
          this.dealerLabelValue = this.dealerDefaultValue;
          this.getShokonCodeByKentemId(this.kentemId);
          this.dealerDialog = false;
        }
      }
      this.dealerLoading = false;
      this.reeseLoading = false;
      this.referenceLoading = false;
    },
    /**
     *
     */
    getData() {
      return Promise.all([
        Axios.get(this.dealerUrl, {
          headers: {
            Authorization: this.bearer,
          },
        }),
        Axios.get(this.reeseUrl, {
          headers: {
            Authorization: this.bearer,
          },
        }),
      ])
        .then(([dealer, reese]) => {
          const data = {};
          data.dealer = dealer.data;
          data.reese = reese.data;
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     *
     * @param {String} code
     */
    setDealerKentemIdByCode(code) {
      Axios.get(
        this.kentemIdUrl + `/api/v1/kentem_information/shokon/${code}`,
        {
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            this.dealerFormValue = res.data.data.kentem_id;
          } else {
            this.dealerLabelValue = this.$i18n.translate("unknown");
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getShokonCodeByKentemId(id) {
      Axios.get(
        this.kentemIdUrl + `/api/v1/kentem_information/shokon_code/${id}`,
        {
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            this.dealerShokonCode = res.data.data;
            if (this.dealerShokonCode === "kshh") {
              this.getShokonCodeByCustomerKentemId(this.customerKentemId);
            } else {
              this.getBillingInfo(res.data.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getShokonCodeByCustomerKentemId(id) {
      Axios.get(this.kentemIdUrl + `/api/v1/kentem_information/${id}`, {
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            if (
              res.data.data.shokon_code == null ||
              res.data.data.shokon_code == ""
            ) {
              this.getKentemInfo(id);
            } else {
              this.dealerShokonCode = res.data.data.shokon_code;
              this.getBillingInfoByCustomerKentemID(res.data.data.shokon_code);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getBillingInfo(id) {
      Axios.get(this.kentemIdUrl + `/api/v1/sale_information/shokon/${id}`, {
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.billingItems = res.data.data;
            this.setBillingInfo();
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getBillingInfoByCustomerKentemID(id) {
      Axios.get(this.kentemIdUrl + `/api/v1/user_information/shokon/${id}`, {
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.billingItems = res.data.data;
            this.setBillingInfoByCustomerKentemID();
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getKentemInfo(id) {
      // IDの先頭文字に応じてモードとエンドポイントを設定
      const modes = {
        U: { mode: "company", endpoint: `/api/v1/geniee/company_info/${id}` },
        P: { mode: "dealer", endpoint: `/api/v1/geniee/dealer_info/${id}` },
      };
      const firstChar = id.charAt(0); // IDの先頭文字を取得
      const config = modes[firstChar];
      if (!config) {
        return; // UでもPでもなければ処理を中断
      }
      const { mode, endpoint } = config;
      // エンドポイントにリクエストを送信
      Axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
        },
      })
        .then((res) => {
          if (res.data) {
            const mapping = {
              company: {
                zip: "zip_cd",
                name: "company_name",
                shokon_code: "shokon_code",
              },
              dealer: {
                zip: "item_005",
                name: "dealer",
                shokon_code: "partner_code",
              },
            };
            const map = mapping[mode];
            this.dealerZipFormValue = res.data[map.zip] ?? "";
            this.dealerAdd1FormValue = res.data.geocode_text ?? "";
            this.dealerLabelValue = res.data[map.name] ?? "";
            this.dealerFormValue = this.dealerLabelValue;
            this.dealerShokonCodeFormValue =  res.data.shokon_code ?? "";
            this.canEnterDealerInfo();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     *
     * @param {Object} item
     */
    async selectDealerItem(item) {
      this.dealerFormValue = item[this.dealerLabelName];
      this.dealerLabelValue = item[this.dealerLabelName];
      this.bearer = await Token(this.tokenUrl);
      if (item[this.dealerIdName] === "kshh") {
        this.getShokonCodeByCustomerKentemId(this.customerKentemId);
      } else {
        this.getShokonCodeByKentemId(item[this.dealerIdName]);
      }
      this.dealerDialog = false;
    },
    /**
     *
     */
    async selectReferenceItem() {
      this.referenceLabelValue = this.referenceItems[0]["reference_number"];
      this.referenceFormValue = this.referenceItems[0]["reference_number"];
      this.bearer = await Token(this.tokenUrl);
      this.getDealerByReferenceId(this.referenceFormValue);
      this.referenceDialog = false;
    },
    /**
     *
     */
    async setBillingInfo() {
      const name1 = this.billingItems["name1"];
      const name2 = this.billingItems["name2"];
      this.dealertNameFormValue = "";
      this.dealerLabelValue =
        name2 && name2.trim() !== "" ? `${name1} ${name2}` : name1;
      this.dealerFormValue = this.dealerLabelValue;
      this.dealerZipFormValue = this.billingItems["zip"];
      this.dealerAdd1FormValue = this.billingItems["add1"];
      this.dealerAdd2FormValue = this.billingItems["add2"];
      this.dealerShokonCodeFormValue = this.dealerShokonCode;
    },
    /**
     *
     */
    async setBillingInfoByCustomerKentemID() {
      const name1 = this.billingItems["name1"];
      const name2 = this.billingItems["name2"];
      this.dealertNameFormValue = this.contactName;
      this.dealerLabelValue =
        name2 && name2.trim() !== "" ? `${name1} ${name2}` : name1;
      this.dealerFormValue = this.dealerLabelValue;
      this.dealerZipFormValue = this.billingItems["zip"];
      this.dealerAdd1FormValue = this.billingItems["add1"];
      this.dealerAdd2FormValue = this.billingItems["add2"];
      this.dealerShokonCodeFormValue = this.dealerShokonCode;
      this.canEnterDealerInfo();
    },
    /**
     *
     */
    async canEnterDealerInfo() {
      this.dealerReadonly = false;
      this.dealerDisabled = false;
      this.dealerZipReadonly = false;
      this.dealerZipDisabled = false;
      this.dealerAdd1Readonly = false;
      this.dealerAdd1Disabled = false;
      this.dealerAdd2Readonly = false;
      this.dealerAdd2Disabled = false;
      this.dealertNameReadonly = false;
      this.dealertNameDisabled = false;
    },
    /**
     *
     */
    async noCanEnterDealerInfo() {
      this.dealerReadonly = true;
      this.dealerDisabled = true;
      this.dealerZipReadonly = true;
      this.dealerZipDisabled = true;
      this.dealerAdd1Readonly = true;
      this.dealerAdd1Disabled = true;
      this.dealerAdd2Readonly = true;
      this.dealerAdd2Disabled = true;
      this.dealertNameReadonly = true;
      this.dealertNameDisabled = true;
    },
    /**
     *
     */
    async clearDealerInfo() {
      this.dealerFormValue = "";
      this.dealerShokonCodeFormValue = "";
      this.dealerZipFormValue = "";
      this.dealerAdd1FormValue = "";
      this.dealerAdd2FormValue = "";
      this.dealertNameFormValue = "";
    },
    /**
     *
     */
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
    /**
     *
     * @param {Object} item
     */
    async selectreeseItem(item) {
      this.reeseCompanyNameFormValue = item[this.reeseCompanyLabelName];
      this.getShokonCodeByCmsId(item[this.reeseCompanyCmsIdLabelName]);
      this.bearer = await Token(this.tokenUrl);
      this.reeseCompanyNameDialog = false;
    },
    /**
     *
     * @param {String} id
     */
    getShokonCodeByCmsId(id) {
      Axios.get(this.kentemIdUrl + `/api/v1/reese/shokon_code/${id}`, {
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.reeseCompanyShokonCodeFormValue = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>