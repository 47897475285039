<template>
  <v-container>
    <v-card>
      <v-card-title>
        出荷物ダッシュボード
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        item-value="id"
			  items-per-page=50
        class="elevation-1"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.item_code">
              <td v-if="item.base_kubun==='はぴロジ'" rowspan="2">{{ item.item_code }}</td>
              <td v-if="item.base_kubun==='はぴロジ'" rowspan="2">{{ item.item_name }}</td>
              <td :class="{'red--text': (item.count !== '-' && item.count < item.latest_month)}">{{ item.base_kubun }}</td>
              <td :class="{'red--text': (item.count !== '-' && item.count < item.latest_month)}">{{ item.count || '-' }}</td>
              <td :class="{'red--text': (item.count !== '-' && item.count < item.latest_month)}">{{ item.latest_month || '-' }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    // Laravel から受け取るデータ
    initialHeaders: {
      type: Array,
      required: true,
    },
    initialItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: this.initialHeaders,
      items: this.initialItems,
    };
  },
};
</script>
<style scoped>
tbody {
  tr:hover {
     background-color: transparent !important;
  }
}
</style>